import * as React from 'react';
import { OverlayTrigger, Popover } from 'react-bootstrap';
import { RouteComponentProps } from 'react-router';
import { Link } from 'react-router-dom';
import { API_BASE_URL, Common, MyAccountConstants, PathConstants } from '../../../common/constants/Constants';
import { container } from '../../../core/startup/inversify/inversify.config';
import { TYPES } from '../../../core/startup/inversify/types';
import { IDialogBox } from '../../../core/utilities/ui/DialogBox';
import { ISnackbar } from '../../../core/utilities/ui/Snackbar';
import { IUtilities } from '../../../core/utilities/Utilities';
import { ClientType, Role } from '../../../model/Common/Enums';
import { IHeaderInfoModel, initialHeaderInfoModel } from '../../../model/IHeaderInfoModel';
import { ClientTypes, ITaxClient, ITaxpayer } from '../../../model/ITaxClient';
import {
	ISignerModel,
	isIndividual,
	isMutual,
	isPartnership,
	ITaxReturn,
	IMarriedJointTaxReturn,
} from '../../../model/TaxReturn/TaxReturn';
import { ChangeNumber } from './ChangeNumber';
import { Header } from './header';
import { MyAccount } from './MyAccount';
import TaxpayerHelper from '../signflow/Helper/TaxpayerHelper';
import { initializeAppInsights } from '../../../logger/AppInsights';
import { getCompanyInfo, injectPendoScript, getLoggedInUserInfo, IPendoMetadata, getPendoMetadata } from '../../common/HelperFunctions';
import SessionTimeoutPage from 'src/components/Account/SessionTimeout';

declare global {
	interface Window {
		pendo: any;
	}
}

export type SlideBarProps = {
	headerInfo: IHeaderInfoModel;
	taxdocument: ITaxReturn;
	signerData: ISignerModel[];

	fetchHeaderInfo(successCallback?: () => void): void;
	fetchSigners(): void;
	fetchTaxDocumentClientType(): void;
	fetchTaxDocument(successCallback?: () => void): void;

	getSessionTimeOutSeconds(clientid: string, callback?: any): void;
	updateSpouseMail(id: string, newMail: string, type: ClientType, callback?: any): void;
	updateSpouseMobile(
		id: string,
		mobile: string,
		type: ClientType,
		countryCode: string,
		ssn: string,
		callback?: any,
	): void;

	updateTaxDocument(taxReturn: ITaxReturn, callback?: () => void): void;
} & RouteComponentProps<{}>;

export interface DefaultLayoutState {
	headerInfo: IHeaderInfoModel;
	showMyAccountPopUp: boolean;
	showAccessCodePopUp: boolean;
	emailAddress: string;
	mobileNumber: string;
	countryCode: string;
	ssn: string;
	showChangeNumberPopUp: boolean;
	showMyDownloadPopUp: boolean;
	screenShareInProgress: boolean;
	profileData: any;
	isPendoInjected: boolean;
	idleSessionTimeoutInSeconds: number;
	sessionExpired: boolean;
	headerTextColor: string;
	headerBackColor: string;
	pendoMetadata: IPendoMetadata;
}

const utilities = container.get<IUtilities>(TYPES.IUtilities);
const snackbar = container.get<ISnackbar>(TYPES.ISnackbar);
const dialogBox = container.get<IDialogBox>(TYPES.IDialogBox);

export class SlideBar extends React.Component<SlideBarProps, DefaultLayoutState> {
	webrtcRef: any;

	constructor(props: SlideBarProps) {
		super(props);

		let param: any = this.props.match.params;
		(window as any).Variables.clientId = param.id;

		this.state = {
			headerInfo: initialHeaderInfoModel,
			showMyAccountPopUp: false,
			showAccessCodePopUp: false,
			emailAddress: '',
			mobileNumber: '',
			countryCode: '',
			ssn: '',
			showChangeNumberPopUp: false,
			showMyDownloadPopUp: false,
			screenShareInProgress: false,
			profileData: undefined,
			isPendoInjected: false,
			idleSessionTimeoutInSeconds: 0,
			sessionExpired: false,
			headerTextColor: '',
			headerBackColor: '',
			pendoMetadata: {
				isTestCompany: false,
				subscription: '',
			},
		};

		this.webrtcRef = React.createRef();
	}

	componentDidMount() {
		const { fetchTaxDocument, fetchSigners, fetchTaxDocumentClientType, getSessionTimeOutSeconds } = this.props;
		fetchSigners();
		fetchTaxDocument(() => {
			fetchTaxDocumentClientType();
		});
		const pathname = this.props.location?.pathname?.split('/');
		if (pathname) {
			const clientGuid = pathname[pathname.length - 1];
			getCompanyInfo(clientGuid, this.updateProfileData);
			getSessionTimeOutSeconds(clientGuid, this.setSessionTimeout);
			getPendoMetadata(clientGuid, this.setPendoMetadata);
			initializeAppInsights(clientGuid);
		}
	}

	setSessionTimeout = (sessionTimeout: number) => {
		this.setState({ idleSessionTimeoutInSeconds: sessionTimeout });
		this.startSessionTimeoutTimer();
	};

	startSessionTimeoutTimer = () => {
		const { idleSessionTimeoutInSeconds } = this.state;
		const timeoutDuration = idleSessionTimeoutInSeconds * 1000;
		setTimeout(() => {
			this.setState({ sessionExpired: true });
		}, timeoutDuration);
	};

	componentDidUpdate(prevProps: Readonly<SlideBarProps>, prevState: Readonly<DefaultLayoutState>): void {
		if (!this.state.isPendoInjected && this.state.profileData && this.state.pendoMetadata) {
			const result = getLoggedInUserInfo(this.props.taxdocument);
			if (result) {
				const { companyId, companyName, userId } = this.state.profileData;
				const { name, email } = result;
				const isTestCompany = this.state.pendoMetadata?.isTestCompany ?? false;
				const subscription = this.state.pendoMetadata?.subscription ?? "";
				injectPendoScript(
					companyId,
					companyName,
					userId,
					name,
					email,
					isTestCompany,
					subscription,
					this.state.isPendoInjected,
					this.setIsPendoInjected,
				);
			}
		}
	}

	setPendoMetadata = (data: IPendoMetadata) => {
		this.setState({ pendoMetadata: data });
	};

	updateProfileData = (data: any) => {
		this.setState({ profileData: data });
	};

	setIsPendoInjected = (isPendoInjected: boolean) => {
		this.setState({ isPendoInjected });
	};

	getMyaccount = () => {
		this.setState({
			showMyAccountPopUp: true,
		});
	};

	onCancelMyAccountPopUp = () => {
		this.setState({
			showMyAccountPopUp: false,
		});
	};

	onChangeMobileNumberClick = () => {
		this.setState({
			showChangeNumberPopUp: true,
		});
	};

	onTaxClientMobileUpdateSuccess = (clientType: ClientType) => {
		switch (clientType) {
			default:
				snackbar.show(MyAccountConstants.StatusMessage.UpdateInformation);
				break;
		}
	};

	onHideChangeNumberPopUp = () => {
		this.setState({
			showChangeNumberPopUp: false,
		});
	};

	onUpdateDetails = (editedEmailAddress: string, editedMobileNumber: string, editedCountryCode: string) => {
		if (this.props.headerInfo.loggedInUserInfo.role.toString() === Role[Role.CPA].toString()) {
			this.setState({ showMyAccountPopUp: false, showAccessCodePopUp: false });
			return false;
		}

		if (editedEmailAddress.trim() === '' || !utilities.isValidEmailAddress(editedEmailAddress)) {
			snackbar.show(MyAccountConstants.WarningMessage.InvalidEmailAddress);
			return false;
		} else if (utilities.validateTenDigitphoneNumber(editedMobileNumber)) {
			const taxDocument = this.props.taxdocument;
			const clientId: number =
				taxDocument && taxDocument.clientType == ClientType.Spouse && isMutual(taxDocument)
					? taxDocument.taxpayer.id
					: taxDocument && taxDocument.clientType == ClientType.Taxpayer && isMutual(taxDocument)
					? taxDocument.spouse.id
					: 0;

			const ssnValue: string =
				taxDocument && taxDocument.clientType == ClientType.Spouse && isMutual(taxDocument)
					? taxDocument.taxpayer.ssn
					: taxDocument && taxDocument.clientType == ClientType.Taxpayer && isMutual(taxDocument)
					? taxDocument.spouse.ssn
					: '';

			if (isMutual(taxDocument)) {
				if (taxDocument.clientType == ClientType.Spouse) {
					taxDocument.taxpayer.email = editedEmailAddress;
					taxDocument.taxpayer.mobileNumber = editedMobileNumber;
					taxDocument.taxpayer.countryCode = editedCountryCode;
					this.props.updateTaxDocument(taxDocument);
				} else if (taxDocument.clientType == ClientType.Taxpayer) {
					taxDocument.spouse.email = editedEmailAddress;
					taxDocument.spouse.mobileNumber = editedMobileNumber;
					taxDocument.spouse.countryCode = editedCountryCode;
					this.props.updateTaxDocument(taxDocument);
				}
			}

			this.setState(
				{ emailAddress: editedEmailAddress, mobileNumber: editedMobileNumber, countryCode: editedCountryCode },
				() => {
					var secondSignerClientType = TaxpayerHelper.getSecondSignerType(
						this.props.taxdocument as IMarriedJointTaxReturn,
						this.props.signerData,
						TaxpayerHelper.getClientId(),
					);

					this.props.updateSpouseMail(clientId.toString(), this.state.emailAddress, secondSignerClientType);

					this.props.updateSpouseMobile(
						clientId.toString(),
						this.state.mobileNumber,
						secondSignerClientType,
						this.state.countryCode,
						ssnValue,
						() => {
							this.onTaxClientMobileUpdateSuccess(taxDocument.clientType);
						},
					);
				},
			),
				this.setState({ showChangeNumberPopUp: false });
			this.setState({ showMyAccountPopUp: false });
		}
	};

	onSaveNumberClick = (changedMobileNumber: string, changedCountryCode: string) => {
		if (this.props.headerInfo.loggedInUserInfo.role.toString() === Role[Role.CPA].toString()) {
			this.onHideChangeNumberPopUp();
		} else {
			if (utilities.validateTenDigitphoneNumber(changedMobileNumber)) {
				const taxDocument = this.props.taxdocument;
				const taxDocumentClient: any = this.props.match.params;

				const clientId: number =
					taxDocument && taxDocument.clientType == ClientType.Taxpayer && isIndividual(taxDocument)
						? taxDocument.taxpayer.id
						: taxDocument && taxDocument.clientType == ClientType.Spouse && isMutual(taxDocument)
						? taxDocument.spouse.id
						: taxDocument && taxDocument.clientType == ClientType.Taxpayer && isMutual(taxDocument)
						? taxDocument.taxpayer.id
						: taxDocument && taxDocument.clientType == ClientType.Partnership && isPartnership(taxDocument)
						? taxDocument.partnership.id
						: 0;

				const ssnValue: string =
					taxDocument && taxDocument.clientType == ClientType.Taxpayer && isIndividual(taxDocument)
						? taxDocument.taxpayer.ssn
						: taxDocument && taxDocument.clientType == ClientType.Spouse && isMutual(taxDocument)
						? taxDocument.spouse.ssn
						: taxDocument && taxDocument.clientType == ClientType.Taxpayer && isMutual(taxDocument)
						? taxDocument.taxpayer.ssn
						: taxDocument && taxDocument.clientType == ClientType.Partnership && isPartnership(taxDocument)
						? taxDocument.partnership.ssn
						: '';

				this.setState({ mobileNumber: changedMobileNumber, countryCode: changedCountryCode }, () => {
					this.props.updateSpouseMobile(
						clientId.toString(),
						this.state.mobileNumber,
						taxDocument.clientType,
						this.state.countryCode,
						ssnValue,
						() => {
							this.onTaxClientMobileUpdateSuccess(taxDocument.clientType);
						},
					);
				});

				if (taxDocument.clientType == ClientType.Taxpayer && isIndividual(taxDocument)) {
					taxDocument.taxpayer.mobileNumber = changedMobileNumber;
					taxDocument.taxpayer.countryCode = changedCountryCode;
					this.props.updateTaxDocument(taxDocument);
				} else if (taxDocument.clientType == ClientType.Partnership && isPartnership(taxDocument)) {
					taxDocument.partnership.mobileNumber = changedMobileNumber;
					taxDocument.partnership.countryCode = changedCountryCode;
					this.props.updateTaxDocument(taxDocument);
				} else if (taxDocument.clientType == ClientType.Spouse && isMutual(taxDocument)) {
					taxDocument.spouse.mobileNumber = changedMobileNumber;
					taxDocument.spouse.countryCode = changedCountryCode;
					this.props.updateTaxDocument(taxDocument);
				} else if (taxDocument.clientType == ClientType.Taxpayer && isMutual(taxDocument)) {
					taxDocument.taxpayer.mobileNumber = changedMobileNumber;
					taxDocument.taxpayer.countryCode = changedCountryCode;
				}
				this.setState({ showChangeNumberPopUp: false });
			}
		}
	};

	private getPartnerShip(): ITaxClient | undefined {
		const taxDocument = this.props.taxdocument;
		if (taxDocument && isPartnership(taxDocument)) {
			return taxDocument.partnership;
		}
		return undefined;
	}

	private getTaxPayerInfo(): ITaxpayer | undefined {
		const taxDocument = this.props.taxdocument;
		if (taxDocument && isIndividual(taxDocument)) {
			return taxDocument.taxpayer;
		} else if (isMutual(taxDocument)) {
			if (
				taxDocument.documentSettings.deliverySettings.deliverTo == ClientTypes.Taxpayer &&
				taxDocument.clientType == ClientType.Taxpayer
			) {
				return taxDocument.taxpayer;
			} else if (
				taxDocument.documentSettings.deliverySettings.deliverTo == ClientTypes.Taxpayer &&
				taxDocument.clientType == ClientType.Spouse
			) {
				return taxDocument.spouse;
			} else if (
				taxDocument.documentSettings.deliverySettings.deliverTo == ClientTypes.Spouse &&
				taxDocument.clientType == ClientType.Spouse
			) {
				return taxDocument.spouse;
			} else if (
				taxDocument.documentSettings.deliverySettings.deliverTo == ClientTypes.Spouse &&
				taxDocument.clientType == ClientType.Taxpayer
			) {
				return taxDocument.taxpayer;
			}
		}
		return undefined;
	}

	private getSpouseInfo(): ITaxpayer | undefined {
		const taxDocument = this.props.taxdocument;
		if (taxDocument && isMutual(taxDocument)) {
			if (taxDocument.clientType == ClientType.Taxpayer) {
				if (taxDocument.documentSettings.deliverySettings.deliverTo == ClientTypes.Taxpayer) {
					return taxDocument.spouse;
				}
			} else {
				if (taxDocument.documentSettings.deliverySettings.deliverTo == ClientTypes.Spouse) {
					return taxDocument.taxpayer;
				}
			}
		}
		return undefined;
	}

	private getMobileNumber(): string {
		const taxDocument = this.props.taxdocument;

		if (taxDocument != null && taxDocument != undefined) {
			if (isIndividual(taxDocument)) {
				return taxDocument.taxpayer.mobileNumber != null && taxDocument.taxpayer.mobileNumber != undefined
					? taxDocument.taxpayer.mobileNumber
					: '';
			} else if (isPartnership(taxDocument)) {
				return taxDocument.partnership.mobileNumber != null && taxDocument.partnership.mobileNumber != undefined
					? taxDocument.partnership.mobileNumber
					: '';
			} else if (isMutual(taxDocument)) {
				if (taxDocument.documentSettings.deliverySettings.deliverTo == ClientTypes.Taxpayer) {
					if (taxDocument.clientType == ClientType.Taxpayer) {
						return taxDocument.taxpayer.mobileNumber != null && taxDocument.taxpayer.mobileNumber != undefined
							? taxDocument.taxpayer.mobileNumber
							: '';
					} else {
						return taxDocument.spouse.mobileNumber != null && taxDocument.spouse.mobileNumber != undefined
							? taxDocument.spouse.mobileNumber
							: '';
					}
				} else if (taxDocument.documentSettings.deliverySettings.deliverTo == ClientTypes.Spouse) {
					if (taxDocument.clientType == ClientType.Taxpayer) {
						return taxDocument.taxpayer.mobileNumber != null && taxDocument.taxpayer.mobileNumber != undefined
							? taxDocument.taxpayer.mobileNumber
							: '';
					} else {
						return taxDocument.spouse.mobileNumber != null && taxDocument.spouse.mobileNumber != undefined
							? taxDocument.spouse.mobileNumber
							: '';
					}
				}
			}
		}
		return '';
	}

	private getCountryCode(): string {
		const taxDocument = this.props.taxdocument;

		if (taxDocument != null && taxDocument != undefined) {
			if (isIndividual(taxDocument)) {
				return taxDocument.taxpayer.countryCode != null && taxDocument.taxpayer.countryCode != undefined
					? taxDocument.taxpayer.countryCode
					: '';
			} else if (isPartnership(taxDocument)) {
				return taxDocument.partnership.countryCode != null && taxDocument.partnership.countryCode != undefined
					? taxDocument.partnership.countryCode
					: '';
			} else if (isMutual(taxDocument)) {
				if (taxDocument.documentSettings.deliverySettings.deliverTo == ClientTypes.Taxpayer) {
					if (taxDocument.clientType == ClientType.Taxpayer) {
						return taxDocument.taxpayer.countryCode != null && taxDocument.taxpayer.countryCode != undefined
							? taxDocument.taxpayer.countryCode
							: '';
					} else {
						return taxDocument.spouse.countryCode != null && taxDocument.spouse.countryCode != undefined
							? taxDocument.spouse.countryCode
							: '';
					}
				} else if (taxDocument.documentSettings.deliverySettings.deliverTo == ClientTypes.Spouse) {
					if (taxDocument.clientType == ClientType.Taxpayer) {
						return taxDocument.taxpayer.countryCode != null && taxDocument.taxpayer.countryCode != undefined
							? taxDocument.taxpayer.countryCode
							: '';
					} else {
						return taxDocument.spouse.countryCode != null && taxDocument.spouse.countryCode != undefined
							? taxDocument.spouse.countryCode
							: '';
					}
				}
			}
		}
		return '';
	}

	private handleLogout() {
		dialogBox.confirm(
			Common.DialogBox.ConfirmationTitle,
			Common.DialogBox.ConfrimMessage,
			Common.DialogBox.No,
			Common.DialogBox.Yes,
			function (result: boolean) {
				if (result) {
					(window as any).location.href = API_BASE_URL + PathConstants.Logout + (window as any).Variables.clientId;
				}
			}.bind(this),
		);
	}

	render() {
		let contactPersonFullName: string = '';
		const contactPerson = this.props.headerInfo.contactPerson;
		contactPersonFullName += contactPerson.firstName;
		if (contactPerson.middleName && contactPerson.middleName.length > 0) {
			contactPersonFullName += ' ' + contactPerson.middleName;
		}
		if (contactPerson.lastName && contactPerson.lastName.length > 0) {
			contactPersonFullName;
			contactPersonFullName += ' ' + contactPerson.lastName;
		}

		const { taxdocument, signerData } = this.props;
		var taxpayer = TaxpayerHelper.getSecondSigner(
			taxdocument as IMarriedJointTaxReturn,
			signerData,
			TaxpayerHelper.getClientId(),
		);

		const contactInfoPopover = (
			<Popover
				id='contactInfoPopover'
				placement='right'
				className='popover-contact'>
				<h3 className='popover-header'>Contact Information</h3>
				<div
					className='popover-body'
					data-test-auto='3464EE21-1DF8-4F1F-BE4B-D838ACE36298'>
					<div className='medium'>{contactPersonFullName} </div>
					<div className='mail'>
						<a href={'mailto:' + contactPerson.email}>{contactPerson.email}</a>
					</div>
					<div>
						<strong className='strong-phone'>{utilities.formateFax(contactPerson.phoneNumber)}</strong>
						{contactPerson && contactPerson.extension && contactPerson.extension.length > 0 && (
							<strong className='strong-phone'>Ext: {contactPerson.extension} </strong>
						)}
					</div>
				</div>
			</Popover>
		);

		const accountPopover = (
			<Popover
				id='accountPopover'
				placement='bottom'
				className='popover-myaccount'>
				<div className='popover-body'>
					<ul className='account-menu'>
						<li>
							<Link
								to={'#'}
								onClick={() => this.getMyaccount()}
								data-test-auto='65AD7EA3-7B05-43C9-B862-F079DE711606'>
								<i className='fa fa-user ddl-icon'></i>
								<span>My Account</span>
							</Link>
						</li>
					</ul>
				</div>
			</Popover>
		);

		const headerData = this.props.headerInfo;
		let headerBackColor = 'green';
		let headerTextColor = 'white';
		if (this.props.headerInfo.brandingSettings) {
			headerBackColor = this.props.headerInfo.brandingSettings.coverPageSetting.bgColorCode;
			headerTextColor = this.props.headerInfo.brandingSettings.coverPageSetting.foreColorCode;
		}
		if (this.state.sessionExpired) {
			return (
				<SessionTimeoutPage
					companyLogo={headerData.companyWhiteLogoPath}
					companyName={headerData.companyName}
					headerTextColor={headerTextColor}
					headerBackColor={headerBackColor}
				/>
			);
		}

		return (
			<React.Fragment>
				<div className='row header-container'>
					<div className={'header-nav'}>
						<div
							className='header-left-container'
							data-test-auto='951602DF-76D9-480A-BA0F-D12E216FBB2B'>
							<input
								type='checkbox'
								className='openSidebarMenu'
								id='openSidebarMenu'
							/>
							<label
								htmlFor='openSidebarMenu'
								className='sidebarIconToggle'>
								<div className='spinner diagonal part-1'></div>
								<div className='spinner horizontal'></div>
								<div className='spinner diagonal part-2'></div>
							</label>
							<div id='sidebarMenu'>
								<ul className='sidebar navbar-nav ul-slidebar'>
									<li className='li-nav-first'>
										<h4 title={this.props.headerInfo.clientName}>{this.props.headerInfo.clientName}</h4>
									</li>

									<li
										data-test-auto='9E3149F1-20E9-48E8-9067-5E70360C410F'
										className='li-nav-second'>
										<span
											className='span-nav'
											title="Contact Person's Information">
											<OverlayTrigger
												data-test-auto='CB7E32C5-518D-40B5-8E8F-E75C9A89CF58'
												rootClose
												trigger='click'
												onEnter={() => document.body.click()}
												overlay={contactInfoPopover}
												placement='right'>
												<a>Contact Person</a>
											</OverlayTrigger>
										</span>
									</li>

									<li className='li-nav'>
										<span className='span-nav'>
											<a
												onClick={() => this.getMyaccount()}
												data-test-auto='65AD7EA3-7B05-43C9-B862-F079DE711606'>
												My Account
											</a>
										</span>
									</li>

									<li className='li-nav'>
										<span
											className='span-nav'
											onClick={() => this.handleLogout()}>
											Logout
										</span>
									</li>
								</ul>
							</div>
						</div>
						<Header
							companyName={this.props.headerInfo.companyName}
							companyLogo={this.props.headerInfo.companyWhiteLogoPath}
						/>
					</div>
				</div>

				<ChangeNumber
					onHideChangeNumberPopUp={this.onHideChangeNumberPopUp}
					showState={this.state.showChangeNumberPopUp}
					mobileNumber={this.getMobileNumber()}
					countryCode={this.getCountryCode()}
					onSaveNumberClick={this.onSaveNumberClick}
				/>

				<MyAccount
					key='value'
					onHide={this.onCancelMyAccountPopUp}
					showState={this.state.showMyAccountPopUp}
					onChangeNumberClick={this.onChangeMobileNumberClick}
					onSaveButtonClick={this.onUpdateDetails}
					taxDocument={this.props.taxdocument}
					partnerShip={this.getPartnerShip()}
					taxPayer={this.getTaxPayerInfo()}
					spouse={taxpayer.isDeceased ? undefined : this.getSpouseInfo()}
				/>

				{this.props.children}
			</React.Fragment>
		);
	}
}
